import React, { FC } from "react";
import { StaffResponse } from "../../types/staff";
import { useLoginContext } from "../../providers/LoginContextProvider";

type ShouldRender = (
  currentStaff: StaffResponse
) => () => boolean | boolean | undefined;

type Props = {
  children: React.ReactNode;
  shouldRender?: ShouldRender;
};

const shouldRenderCheck = (
  currentStaff: StaffResponse,
  shouldRender?: ShouldRender
) => {
  switch (typeof shouldRender) {
    case "function":
      return shouldRender(currentStaff);
    case "undefined":
      return currentStaff.is_toypo_member;
    case "boolean":
      return shouldRender;
    default:
      return false;
  }
};

export const ConditionalRender: FC<Props> = ({ children, shouldRender }) => {
  const { currentStaff } = useLoginContext();
  const reject = !shouldRenderCheck(currentStaff, shouldRender);

  if (reject) {
    return null;
  }

  return <>{children}</>;
};
