import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";

type UserDataRowProps = {
  label: string;
  children?: React.ReactNode;
};

const StyledUserDataTitle = styled("div")(({ theme }) => ({
  fontSize: 13,
  fontWeight: "bold",
  color: "#9A9A9A",
  flexBasis: 103,
  marginRight: theme.spacing(1.5),
}));

const StyledUserDataContent = styled("div")({
  flex: 1,
  wordBreak: "break-all",
});

export const UserDataRow = ({ label, children }: UserDataRowProps): JSX.Element => (
  <Box my={1} display="flex" alignItems="center">
    <StyledUserDataTitle>{label}</StyledUserDataTitle>
    <StyledUserDataContent style={{ color: children ? undefined : "#9A9A9A" }}>
      {children || "未登録"}
    </StyledUserDataContent>
  </Box>
);
